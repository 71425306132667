'use client';

import React from 'react';
import { useServerInsertedHTML } from 'next/navigation';
import { getCssText } from '../styles';
import { globalStyles } from '../styles/global';
export function ServerStylesheet({
  children
}: Readonly<{
  children: React.ReactNode;
}>) {
  globalStyles();
  useServerInsertedHTML(() => {
    return <style id='stitches' dangerouslySetInnerHTML={{
      __html: getCssText()
    }} />;
  });
  return children;
}