import { KeycloakConfig } from 'keycloak-js';

const URL_KEYCLOAK = process.env.NEXT_PUBLIC_KEYCLOAK_URL;
const REALM_KEYCLOAK = process.env.NEXT_PUBLIC_KEYCLOAK_REALM;
const CLIENT_ID_KEYCLOAK = process.env.NEXT_PUBLIC_KEYCLOAK_CLIENT_ID;

export const keycloakConfig = {
  url: String(URL_KEYCLOAK),
  realm: String(REALM_KEYCLOAK),
  clientId: String(CLIENT_ID_KEYCLOAK),
} as KeycloakConfig;
