'use client';

import { useState, useEffect, createContext, useContext, PropsWithChildren, useMemo, useRef, useCallback } from 'react';
import Keycloak, { KeycloakTokenParsed } from 'keycloak-js';
import { keycloakConfig } from '@/utils/auth';
import { getAccessTokensStorage, removeAccessTokensStorage, setAccessTokensStorage } from '@/utils/localStorage';
import Loading from '@/components/shared/Loading';
import { useRouter } from 'next/navigation';
import { errorMessage } from '@/utils/toast';
const VALIDATION_TIME = 60 * 30;
type UserKeycloakData = {
  token?: string;
  tokenParsed?: Partial<KeycloakTokenParsed>;
  name: string;
};
type AuthContextData = {
  user: UserKeycloakData | null;
  isLoading: boolean;
  signIn: () => Promise<void>;
  signOut: () => void;
};
export const AuthContext = createContext({} as AuthContextData);
export function AuthProvider({
  children
}: PropsWithChildren) {
  const [user, setUser] = useState<UserKeycloakData | null>(null);
  const [isLoading, setIsLoading] = useState(true);
  const {
    push
  } = useRouter();
  const client = useMemo(() => typeof window !== 'undefined' ? new Keycloak(keycloakConfig) : null, []);
  const isInitialized = useRef(false);
  const signIn = () => new Promise<void>((resolve, reject) => {
    if (!client) return reject(new Error('Não foi possível realizar a autenticação com o serviço gov.br'));
    client.login({
      redirectUri: window.location.origin + '/callback'
    });
    resolve();
  });
  const signOut = useCallback(async () => {
    removeAccessTokensStorage();
    client?.logout({
      redirectUri: process.env.KEYCLOAK_LOGOUT_URL || window.location.origin + '/sign-in'
    });
  }, [client]);
  useEffect(() => {
    if (!client || isInitialized.current) return;
    isInitialized.current = true;
    const {
      token,
      refreshToken
    } = getAccessTokensStorage();
    setIsLoading(true);
    client.init({
      token,
      refreshToken
    }).then(success => {
      if (success) {
        setAccessTokensStorage(client.token || '', client.refreshToken || '');
        setUser({
          token: client.token,
          tokenParsed: client.tokenParsed,
          name: client.tokenParsed?.preferred_username || ''
        });
      } else {
        setUser(null);
        removeAccessTokensStorage();
      }
      setIsLoading(false);
    }).catch(() => {
      setIsLoading(false);
    });
  }, [client]);
  useEffect(() => {
    if (!client || !user) return;
    const interval = setInterval(() => {
      client.updateToken(VALIDATION_TIME).then(() => {
        setAccessTokensStorage(client.token || '', client.refreshToken || '');
      });
    }, 5 * 60 * 1000);
    return () => clearInterval(interval);
  }, [client, user]);
  useEffect(() => {
    const handleSessaoExpirada = () => {
      errorMessage('Sua sessão expirou. Entre novamente na aplicação.');
      removeAccessTokensStorage();
      push('/sign-in');
    };
    window.addEventListener('sessaoExpirada', handleSessaoExpirada);
    return () => {
      window.removeEventListener('sessaoExpirada', handleSessaoExpirada);
    };
  }, [push]);
  return <AuthContext.Provider value={{
    signIn,
    signOut,
    user,
    isLoading
  }} data-sentry-element="unknown" data-sentry-component="AuthProvider" data-sentry-source-file="useKeycloakAuth.tsx">
      {children}
    </AuthContext.Provider>;
}
export const useAuth = () => useContext(AuthContext);