import { getCookie, setCookie, deleteCookie } from 'cookies-next';

export const getTokenStorage = () => {
  // if (typeof window !== 'undefined' && localStorage) {
  // return localStorage.getItem('token');
  return getCookie('token');
  // }
  // return null;
};

export const removeTokenStorage = () => {
  // if (typeof window !== 'undefined' && localStorage) {
  localStorage.removeItem('token');
  deleteCookie('token');
  // }
};

export const setTokenStorage = (token: string) => {
  // if (typeof window !== 'undefined' && localStorage) {
  localStorage.setItem('token', token);
  setCookie('token', token);
  // }
};

export const setAccessTokensStorage = (token: string, refreshToken: string) => {
  // if (typeof window !== 'undefined' && localStorage) {
  //   localStorage.setItem('token', token);
  //   localStorage.setItem('refreshToken', refreshToken);
  setCookie('token', token);
  setCookie('refreshToken', refreshToken);
  // }
};

export const removeAccessTokensStorage = () => {
  // if (typeof window !== 'undefined' && localStorage) {
  // localStorage.removeItem('token');
  // localStorage.removeItem('refreshToken');
  deleteCookie('token');
  deleteCookie('refreshToken');
  // }
};

export const getAccessTokensStorage = () => {
  // if (typeof window !== 'undefined' && localStorage) {
  // const token = localStorage.getItem('token') || '';
  // const refreshToken = localStorage.getItem('refreshToken') || '';
  const token = getCookie('token');
  const refreshToken = getCookie('refreshToken');

  return {
    token,
    refreshToken,
  };
  // }

  // return {
  //   token: '',
  //   refreshToken: '',
  // };
};
